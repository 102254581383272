

























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import {
  hasEmployeeAdministratorAuthority,
  hasManagerAuthority,
  hasAdministratorAuthority,
  hasFacilitiesOrEmployeeAdministratorAuthority,
} from '@/shared/directive/has-authority.directive';
import ResignationStaff from '@/domain/resignation/entity/resignation.entity';
import ResignationStaffModal from '@/views/employees/profile/resignation-staff/resignation-staff-modal.component.vue';
import { VbTooltipSlot } from 'bag-of-holding-library';
import EmployeeFilter from '@/domain/employees/entity/filter.entity';
import EmployeeService from '@/domain/employees/service/employee.service';
import MobileHandler from '@/shared/utils/mobile-handler';
import AuthorityStore from '@/domain/security/authority.store';
import AuthorityEnum from '@/domain/security/authority.enum';
import EmployeeModalColumns from './modal/employee-modal-columns.component.vue';

@Component({
  components: {
    CardTitle, VbTooltipSlot, ResignationStaffModal, EmployeeModalColumns,
  },
  directives: {
    hasEmployeeAdministratorAuthority,
    hasManagerAuthority,
    hasAdministratorAuthority,
    hasFacilitiesOrEmployeeAdministratorAuthority,
  },
})
export default class ScheduleHeader extends Vue {
  @Prop() filter!: EmployeeFilter;

  active: boolean = true;

  accompaniment: boolean = true;

  private resignationStaff!: ResignationStaff;

  showResignationStaffModal: boolean = false;

  showColumnsModal: boolean = false;

  modalKey: number = 0;

  userLoggedInId!: string;

  isMobile = false;

  isFacilities: boolean = false;

  isEmployeeAdministrator: boolean = false;

  isAdmin: boolean = false;

  isManager: boolean = false;

  onResize() {
    this.isMobile = MobileHandler.isLessThan(768);
  }

  created() {
    AuthorityStore.fetchedAuthority()
      .then((authorities) => {
        this.isFacilities = authorities
          .hasAuthorities([AuthorityEnum.FACILITIES_ADMINISTRATOR]);
        this.isEmployeeAdministrator = authorities
          .hasAuthorities([AuthorityEnum.EMPLOYEE_ADMINISTRATOR]);
        this.isAdmin = authorities.hasAuthorities([AuthorityEnum.ADMINISTRATOR]);
        this.isManager = authorities.hasAuthorities([AuthorityEnum.MANAGER]);
      });
    EmployeeService.getLoggedUserEmployeeId().then(
      (response: any) => this.userLoggedInId = response,
    );
  }

  applyFilter() {
    this.$emit('filter', this.filter);
  }

  input() {
    this.$emit('input', this.active);
  }

  clickAccompaniment() {
    this.$emit('accompaniment', this.accompaniment);
  }

  close() {
    this.showResignationStaffModal = false;
    this.modalKey += 1;
    this.$emit('refresh');
  }

  go(value: string) {
    this.$router.push(value);
  }

  openColumnsModal(): void{
    this.showColumnsModal = true;
  }

  setVisibleColumns(columns: any[]): void {
    this.$emit('setExtraColumns', columns)
  }
}
