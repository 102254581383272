var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('label-slot',{staticClass:"filter__select",attrs:{"label":'Colaborador'}},[_c('v-text-field',{attrs:{"placeholder":"Digite o nome...","no-data-text":"Informe o nome do colaborador","outlined":"","dense":"","hide-details":""},on:{"input":_vm.sendFilterEmployeeNameDebounce},model:{value:(_vm.filter.employeeName),callback:function ($$v) {_vm.$set(_vm.filter, "employeeName", $$v)},expression:"filter.employeeName"}})],1)],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('label-slot',{staticClass:"filter__select",attrs:{"label":'Cargo'}},[_c('v-autocomplete',{attrs:{"placeholder":"Digite o cargo...","items":_vm.roles,"locale":'pt-br',"item-text":_vm.getRoleText,"outlined":"","dense":"","item-value":"id","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","search-input":_vm.filter.roleInput,"hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.setRoleInput,"click:clear":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.role.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.role),callback:function ($$v) {_vm.$set(_vm.filter, "role", $$v)},expression:"filter.role"}})],1)],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('filter-assignment-debounce',{attrs:{"multiple":true,"value":_vm.filter.assignment},on:{"input":_vm.handleFilterAssignment}})],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('label-slot',{staticClass:"filter__select",attrs:{"label":'Gestor'}},[_c('v-autocomplete',{attrs:{"items":_vm.managers,"item-text":"name","placeholder":"Selecione um gestor...","hide-no-data":"","outlined":"","dense":"","item-value":"id","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","search-input":_vm.filter.managerInput,"hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.findManagers,"click:clear":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.manager.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.manager),callback:function ($$v) {_vm.$set(_vm.filter, "manager", $$v)},expression:"filter.manager"}})],1)],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12","sm":"4","lg":"3"}},[_c('label-slot',{staticClass:"filter__select",attrs:{"label":'Unidade de Negócio'}},[_c('v-autocomplete',{attrs:{"placeholder":"Selecione a unidade","item-text":"name","items":_vm.businessUnits,"outlined":"","item-value":"id","dense":"","clearable":"","multiple":"","chips":"","small-chips":"","deletable-chips":"","search-input":_vm.filter.businessUnitInput,"hide-details":""},on:{"input":_vm.sendFilter,"update:search-input":_vm.setBusinessUnitInput,"click:clear":_vm.sendFilter},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('selection-component',{attrs:{"length":_vm.filter.businessUnit.length,"item":item,"index":index}})]}}]),model:{value:(_vm.filter.businessUnit),callback:function ($$v) {_vm.$set(_vm.filter, "businessUnit", $$v)},expression:"filter.businessUnit"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }