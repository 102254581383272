import Question from './question.entity';

export default class Answer {
  questionId: string;

  questionPosition: number;

  questionType: string;

  value: string;

  constructor(obj: any = {}) {
    this.questionId = obj.questionId || '';
    this.questionPosition = obj.questionPosition || null;
    this.questionType = obj.questionType || '';
    this.value = obj.value || '';
  }

  public static of(question: Question): Answer {
    const answer = new Answer();
    answer.questionPosition = question.position;
    answer.questionType = question.type;
    answer.questionId = question.questionId;
    return answer;
  }
}
