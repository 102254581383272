






































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import ModalComponent from '@/shared/modal/modal.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import EmployeeTalentLevelService from '@/domain/employees/service/employee-talent-level.service';
import EmployeeTalentLevel from '@/domain/employees/entity/employee-talent-level.entity';
import TalentLevelService from '@/domain/talent-level/service/talent-level.service';
import TalentLevel from '@/domain/talent-level/entity/talent-level.entity';
import TalentLevelLabel from '@/shared/talent-level-label.component.vue';
import DateFormat from '@/shared/utils/date-format';
import ModalOptions from '../../../../shared/modal/modal-options.entity';

@Component({
  components: {
    LabelSlot,
    ModalComponent,
    TalentLevelLabel,
  },
})
export default class EmployeeTalentLevelModal extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) showModal!: boolean;

  @Prop({
    type: String,
  }) employeeId!: string;

  @Prop({
    default: [],
  }) history!: EmployeeTalentLevel[];

  newTalentLevel: TalentLevel = new TalentLevel();

  get optionsHandled(): ModalOptions {
    return {
      title: 'Nível de talento',
      titleSize: '20px',
      description: '',
      showOk: false,
      showCancel: true,
      labelCancel: 'Fechar',
      width: 700,
    };
  }

  pagination = {
    page: 0,
    size: 5,
    totalElements: 0,
    totalPage: -1,
    last: false,
  }

  loadingTalentLevels: boolean = false;

  talentLevels: TalentLevel[] = [];

  loadingUpdateTalentLevel: boolean = false;

  loadingHistory: boolean = false;

  created() {
    this.loadTalentLevels();
    this.getHistory();
  }

  async getHistory() {
    if (this.history.length > 0) return;
    try {
      const response = await EmployeeTalentLevelService.getHistory(
        this.employeeId,
        this.pagination.page,
        this.pagination.size,
      );
      this.$emit('fetchHistory', response.data.content);
      this.pagination.totalElements = response.data.totalElements;
      this.pagination.totalPage = response.data.totalPage;
    } catch (error) {
      VbSnackbarService.showError(error.response.data.messages.toString());
      this.close();
    }
  }

  async getMoreHistory() {
    this.loadingHistory = true;
    const response = await EmployeeTalentLevelService
      .getHistory(this.employeeId, this.pagination.page + 1, this.pagination.size);
    this.$emit('fetchHistory', response.data.content);
    this.loadingHistory = false;
    this.pagination.totalElements = response.data.totalElements;
    this.pagination.page += 1;
    this.pagination.size = response.data.size;
  }

  async updateTalentLevel() {
    this.loadingUpdateTalentLevel = true;
    const { data } = await EmployeeTalentLevelService
      .update(this.employeeId, null, this.newTalentLevel.id);
    this.history.unshift(data);
    this.loadingUpdateTalentLevel = false;
    this.newTalentLevel = new TalentLevel();
  }

  close() {
    this.$emit('close');
  }

  loadTalentLevels(): void {
    this.loadingTalentLevels = true;
    TalentLevelService.getAllTalentLevels()
      .then((talentLevels: TalentLevel[]) => {
        this.talentLevels = talentLevels;
      })
      .finally(() => {
        this.loadingTalentLevels = false;
      });
  }

  isTalentLevelDisabled(talentLevel: TalentLevel): boolean {
    return !talentLevel.active;
  }

  formatDate(date: string) {
    return DateFormat.timestampToLocal(date.split('T')[0]);
  }
}
