




























































































import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { VbSnackbarService } from 'bag-of-holding-library';
import DatePickerField from '@/shared/date-picker/date-picker-field.component.vue'
import CardTitle from '@/shared/card/card-title.component.vue';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import splitContent from '@/shared/directive/split-content';
import DateHandler from '@/shared/utils/date-format';
import ModalWarning from '@/shared/modal/modal-warning.component.vue';
import Agreement from '@/domain/employees/entity/agreement.entity';
import Rules from '@/shared/rules/rules';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import TooltipIcon from '@/shared/tooltip-icon.component.vue';
import WageService from '@/domain/wages/services/wages.service';
import Wages from '@/domain/wages/entity/wages.entity';
import { Currency } from '@/domain/employees/entity/professional-data.entity';
import SalaryReadjustmentParametrizationService from '@/domain/salary-reajustment-parametrizations/service/salary-readjustment-parametrization.service';
import Assignment from '@/domain/drd/entity/assignment.entity';
import moment from 'moment';
import AgreementListComponent from './agreement-list.component.vue';
import AgreementHeaderComponent from './agreement-header.component.vue';
import RemunerationAgreementTable from './remuneration-agreement-table.component.vue';
import { CurrencyType, FixedAgreement } from './score-utils';
import { ContractType } from './contract-type.type';

@Component({
  components: {
    LabelSlot,
    DatePickerField,
    CardTitle,
    ModalWarning,
    TooltipIcon,
    AgreementListComponent,
    AgreementHeaderComponent,
    RemunerationAgreementTable,
  },
  filters: {
    splitContent,
    formatDate(value: string) {
      return DateHandler.timestampToLocal(value.split('T')[0]);
    },
  },
})
export default class AgreementComponent extends Vue {
  @Prop() fixedAgreementList!: Agreement[];

  @Prop() variableAgreementList!: Agreement[];

  @Prop() totalAgreementList!: Agreement[];

  @Prop() roles!: UpdateAtribute[];

  @Prop() isUnscheduledScore!: boolean;

  @Prop({ type: Boolean, default: true }) showPreviousScore!: boolean;

  @Prop() seniorities!: [];

  @Prop({
    type: Boolean,
  }) editPrevious!: boolean;

  @Prop() assignments!: Assignment[];

  @Prop({ type: Number, default: 0 })
  currentBusinessUnitCode!: number

  rules: Rules = new Rules();

  seniorityRemunerations: Wages[] = [];

  loading: boolean = false;

  @Prop() currency!: Currency;

  @Prop({
    required: true,
  }) contractType!: ContractType;

  @Prop({
    type: Boolean,
    default: false,
  }) unlockBenefitPercent!: boolean;

  minimalReadjustment: number | null = null;

  FixedAgreementEnum = FixedAgreement;

  lastModifiedDateSeniorityRemuneration: string = '';

  get selectedNewRole(): string {
    const role = this.fixedAgreementList.find((it:Agreement) => it.isRole())
    if (!role || !role.newAgreement) {
      return '';
    }

    return `- ${role.newAgreement}`;
  }

  exclusionFlag(description): boolean {
    const noExclusionList: string[] = ['Cargo', 'Senioridade', 'Salário em CLT', 'Ducz', 'Total']
    return noExclusionList.includes(description)
  }

  addNewAgreement() {
    this.$emit('addAgreement')
  }

  removeAgreement(item: Agreement) {
    this.$emit('removeAgreement', item)
  }

  updateTotal() {
    this.$emit('updateTotal')
  }

  onRoleChange(roleId: string) {
    this.loading = true;
    WageService.findWagesByRole(roleId).then((resp: any) => {
      this.seniorityRemunerations = resp;
      this.lastModifiedDateSeniorityRemuneration = this.getLastModifiedDateSeniorityRemuneration()
    }).finally(() => {
      this.loading = false;
    });
  }

  findCurrentParametrization() {
    SalaryReadjustmentParametrizationService.findCurrentParametrization()
      .then(({ data }) => {
        this.minimalReadjustment = data.minimalReadjustment;
      })
  }

  created() {
    this.getCurrentSeniorityRemuneration();
    this.findCurrentParametrization();
  }

  getCurrentSeniorityRemuneration() {
    const currentRole = this.fixedAgreementList
      .filter(agreement => agreement.description === this.FixedAgreementEnum.ROLE)[0]
    if (currentRole) {
      const assignmentFinded = this.assignments
        .find(assignment => assignment.description === currentRole.newAgreement)
      if (assignmentFinded) {
        this.onRoleChange(assignmentFinded.role.id!)
      }
    }
  }

  getLastModifiedDateSeniorityRemuneration() {
    const dates = this.seniorityRemunerations.map(it => moment(it.lastModifiedDate));
    const max = moment.max(dates);
    return max.format('MM/YYYY')
  }

  get currencyIsBr() {
    return this.currency.name === CurrencyType.BRL;
  }
}
