




























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import LabelSlot from '@/shared/slots/label-slot.component.vue';
import Filter from '@/domain/employees/entity/filter.entity';
import SelectionComponent from '@/shared/fields/selection.componet.vue';
import YearMonthPicker from '@/shared/month-year-picker/month-year-select.component.vue';
import EmployeeSummary from '@/domain/schedule/employee/employee-summary.entity';
import UpdateAtribute from '@/domain/employees/entity/update-atribute.entity';
import FilterAssignmentDebounce from '@/shared/filters/assignment/filter-assignment-debouce.vue';
import debounce from '@/shared/utils/debounce';

@Component(
  {
    components: {
      LabelSlot,
      YearMonthPicker,
      SelectionComponent,
      FilterAssignmentDebounce,
    },
  },
)
export default class FilterComponent extends Vue {
  @Prop() options!: Filter;

  @Prop() managers!: EmployeeSummary;

  @Prop() employees!: EmployeeSummary;

  @Prop() roles!: UpdateAtribute[];

  @Prop() businessUnits!: UpdateAtribute;

  @Prop() lastFilter?: Filter;

  auxFilter: Filter = new Filter({});

  filter: Filter = new Filter({});

  created() {
    this.getLastFilter();
    this.sendFilter();
    this.$forceUpdate();
  }

  getLastFilter() {
    if (this.lastFilter) {
      this.filter = this.lastFilter;
    }
  }

  sendFilterEmployeeNameDebounce() {
    if (this.filter.hasEmployeeName && this.filter.employeeName.length < 3) {
      return;
    }
    debounce.debounce(() => this.sendFilter())
  }

  sendFilter() {
    this.$emit('filter', this.filter);
  }

  findManagers(value: string) {
    this.filter.managerInput = value
    this.$emit('findManagers', value);
  }

  findEmployees(value: string) {
    this.filter.employeeInput = value
    this.$emit('findEmployees', value);
  }

  setRoleInput(value: string): void {
    this.filter.roleInput = value
  }

  setBusinessUnitInput(value: string): void {
    this.filter.businessUnitInput = value
  }

  getRoleText(item: UpdateAtribute) {
    return item.active ? item.name : `${item.name} - (inativo)`;
  }

  handleFilterAssignment(assignments: string[]) {
    this.filter.assignment = assignments;
    this.sendFilter()
  }
}
