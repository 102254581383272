



























import { Vue, Component } from 'vue-property-decorator';
import CardTitle from '@/shared/card/card-title.component.vue';
import Employee from '@/domain/employees/entity/employee.entity';
import FilterService from '@/domain/employees/service/filter.service';
import EmployeeService from '@/domain/schedule/employee/employee.service';
import ManagerService from '@/domain/employees/service/employee.service';
import debounce from '@/shared/utils/debounce';
import TableComponent from './employee-table.component.vue';
import FilterComponent from './filter/filter.component.vue';
import EmployeeSummary from '../../../domain/schedule/employee/employee-summary.entity';
import Filter from '../../../domain/employees/entity/filter.entity';
import CareerService from '../../../domain/employees/service/career.service';
import UpdateAtribute from '../../../domain/employees/entity/update-atribute.entity';
import HeaderComponent from './employee-table-header.component.vue';

@Component({
  name: 'EmployeesTableView',
  components: {
    FilterComponent, CardTitle, TableComponent, HeaderComponent,
  },
})
export default class EmployeesTableView extends Vue {
  employees: Employee[] = [];

  filterEmployees: EmployeeSummary[] = [];

  businessUnit: UpdateAtribute[] = [];

  roles: UpdateAtribute[] = [];

  lastFilterManager: string = '';

  lastFilterEmployee: string = '';

  tableLoading: boolean = false;

  lastFilter: Filter = new Filter({});

  showResignationModal: boolean = false;

  extraColumns: any[] = []

  isManager: boolean = false;

  sortBy: Array<string> = ['name'];

  sortDesc: boolean[] = [false];

  pagination: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: this.sortBy,
    sortDesc: this.sortDesc,
    total: 0,
    pageCount: 0,
  }

  filter: Filter = Filter.of({});

  managers: EmployeeSummary[] = [];

  setFilterValues(value: any) {
    this.filter.orderBy.field = value.sortBy.toString();
    this.filter.orderBy.descending = value.sortDesc.toString();
    if (this.pagination.itemsPerPage < value.itemsPerPage) {
      value.page = 1;
    }
    this.pagination = value;
    this.findEmployees();
  }

  getLastFilter() {
    const lastFilter = sessionStorage.getItem('storageFilter');
    if (lastFilter) {
      this.filter = Filter.of(JSON.parse(lastFilter));
      if (this.filter.manager) {
        const manager: any = sessionStorage.getItem('managerStore');
        this.findManagers(manager);
      }
      if (this.filter.employee) {
        const employee: any = sessionStorage.getItem('employeeStore');
        this.findEmployeesByName(employee);
      }
    }
  }

  created() {
    CareerService.getRoles().then((resp: any) => this.roles = resp.data);
    CareerService.getBusinessUnit().then(resp => this.businessUnit = resp);
    this.getLastFilter();
    this.findEmployees();
  }

  updateEmployees(value: Filter) {
    if (value.equals(this.filter)) {
      this.filter = value;
      this.pagination.page = 1;
    }
    sessionStorage.setItem('storageFilter', JSON.stringify(this.filter));
    this.findEmployees();
  }

  findEmployees() {
    this.tableLoading = true;
    FilterService.findEmployees(this.filter, this.pagination.page, this.pagination.itemsPerPage)
      .then((response: any) => {
        this.employees = response.content;
        this.pagination.total = response.totalElements || 0;
        this.pagination.pageCount = response.totalPages;
      })
      .finally(() => this.tableLoading = false);
  }

  findManagersDebounce(value: string) {
    debounce.debounce(() => this.findManagers(value))
  }

  findManagers(value: string) {
    if ((this.lastFilterManager !== value) && value) {
      this.lastFilterManager = value;
      if (value.length > 2) {
        sessionStorage.setItem('managerStore', value)
        ManagerService.findManagersByName(value)
          .then(this.mergeManagers);
      }
    }
  }

  findEmployeesByName(value: string) {
    if ((this.lastFilterEmployee !== value) && value) {
      this.lastFilterEmployee = value;
      if (value.length > 2) {
        sessionStorage.setItem('employeeStore', value)
        EmployeeService.findEmployeesByName(value)
          .then(this.mergeEmployees);
      }
    }
  }

  mergeEmployees(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.filterEmployees.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.filterEmployees.push(employee);
      }
    })
  }

  mergeManagers(employees: EmployeeSummary[]): void {
    const addedIds: string[] = this.managers.map((employee: EmployeeSummary) => employee.id)
    employees.forEach((employee: EmployeeSummary) => {
      if (!addedIds.includes(employee.id)) {
        this.managers.push(employee);
      }
    })
  }

  setExtraColumns(extraColumns: any[]): void {
    this.extraColumns = extraColumns
  }
}
